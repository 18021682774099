<template>
  <ion-list v-if="resources.data.length > 0">
    <ion-item
      v-for="item in resources.data"
      :key="item.id"
      :ref="`items-${item.id}`"
      class="hydrated link"
    >
      <ion-label @click="openPopover(item, $event)">
        <h2>{{ item.name }}</h2>
        <h4>Invoice No: {{ item.id }}</h4>
        <p>
          {{ item.user.user_detail.name }} -
          {{ item.user.student.section_standard.standard.name }} ({{
            item.user.student.section_standard.section.name
          }})
        </p>
        <p>Invoice Amount: Rs. {{ item.gross_amount_in_cent / 100 }}</p>
      </ion-label>
      <!-- <ion-buttons slot="end">
          <ion-button
            :href="`${base_url}api/v1/fee-invoice-print/${item.id}?pat=${token}`"
            :download="`fee_invoice_${item.id}`"
          >
            <ion-icon
              slot="icon-only"
              :ios="documentOutline"
              :md="documentSharp"
              size="small"
            ></ion-icon>
          </ion-button>
        </ion-buttons> -->
    </ion-item>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No invoices found</p>
    </ion-list-header>
  </ion-list>

  <ion-popover
    :is-open="popoverState"
    :event="popoverEvent"
    @didDismiss="setPopoverState(false)"
  >
    <base-popover
      @closePopover="setPopoverState(false)"
      :title="'Session ' + selectedResource.name"
    >
      <ion-item
        button
        @click="setPopoverState(false)"
        :router-link="{
          name: 'invoice.show',
          params: { invoice: selectedResource.id },
        }"
        >View</ion-item
      >
      <ion-item
        button
        @click="setPopoverState(false)"
        :href="`${base_url}api/v1/fee-invoice-print/${selectedResource.id}?pat=${token}`"
        :download="`fee_invoice_${selectedResource.id}`"
        >Download</ion-item
      >
    </base-popover>
  </ion-popover>
</template>
<script>
import base_url from "../../../../apis/base_url";
import BasePopover from "../../../Base/BasePopover.vue";
import {
  IonList,
  IonListHeader,
  IonLabel,
  IonItem,
  alertController,
  IonPopover,
} from "@ionic/vue";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonList,
    IonListHeader,
    IonLabel,
    IonItem,
    IonPopover,
    BasePopover,
  },
  data() {
    return {
      base_url,

      popoverState: false,
      popoverEvent: null,
      selectedResource: null,

      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  computed: {
    filter() {
      return this.$store.getters["invoice/segment"];
    },
    token() {
      return this.$store.getters["auth/token"];
    },
  },

  methods: {
    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });
      return alert;
    },
    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    openPopover(resource, ev) {
      this.popoverEvent = ev;
      this.setSelectedResource(resource);
      this.setPopoverState(true);
    },

    setPopoverState(state) {
      this.popoverState = state;
    },

    setSelectedResource(resource) {
      this.selectedResource = resource;
    },
  },
};
</script>
